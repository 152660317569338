import React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import gallery1 from "../../assets/gallery/1.png";
import gallery2 from "../../assets/gallery/2.png";
import gallery3 from "../../assets/gallery/3.png";
import gallery4 from "../../assets/gallery/4.png";
import gallery5 from "../../assets/gallery/5.png";
import gallery6 from "../../assets/gallery/6.png";
import gallery7 from "../../assets/gallery/7.png";
import gallery8 from "../../assets/gallery/8.png";
import gallery9 from "../../assets/gallery/9.png";
import gallery10 from "../../assets/gallery/10.png";
import gallery11 from "../../assets/gallery/11.png";
import gallery12 from "../../assets/gallery/12.png";
import gallery13 from "../../assets/gallery/13.png";
import gallery14 from "../../assets/gallery/14.png";
import gallery15 from "../../assets/gallery/15.png";
import gallery16 from "../../assets/gallery/16.png";
import gallery17 from "../../assets/gallery/17.png";
import gallery18 from "../../assets/gallery/18.png";
import gallery19 from "../../assets/gallery/19.png";
import gallery20 from "../../assets/gallery/20.png";
import gallery21 from "../../assets/gallery/21.png";
import gallery22 from "../../assets/gallery/22.png";
import gallery23 from "../../assets/gallery/23.png";
import gallery24 from "../../assets/gallery/24.png";
import gallery25 from "../../assets/gallery/25.png";
import gallery26 from "../../assets/gallery/26.png";

const itemData = [
  {
    img: gallery1,
    title: "1",
  },
  {
    img: gallery2,
    title: "2",
  },
  {
    img: gallery3,
    title: "3",
  },
  {
    img: gallery4,
    title: "4",
  },
  {
    img: gallery5,
    title: "5",
  },
  {
    img: gallery6,
    title: "6",
  },
  {
    img: gallery7,
    title: "7",
  },
  {
    img: gallery8,
    title: "8",
  },
  {
    img: gallery9,
    title: "9",
  },
  {
    img: gallery10,
    title: "10",
  },
  {
    img: gallery11,
    title: "11",
  },
  {
    img: gallery12,
    title: "12",
  },
  {
    img: gallery13,
    title: "13",
  },
  {
    img: gallery14,
    title: "14",
  },
  {
    img: gallery15,
    title: "15",
  },
  {
    img: gallery16,
    title: "16",
  },
  {
    img: gallery17,
    title: "17",
  },
  {
    img: gallery18,
    title: "18",
  },
  {
    img: gallery19,
    title: "19",
  },
  {
    img: gallery20,
    title: "20",
  },
  {
    img: gallery21,
    title: "21",
  },
  {
    img: gallery22,
    title: "22",
  },
  {
    img: gallery23,
    title: "23",
  },
  {
    img: gallery24,
    title: "24",
  },
  {
    img: gallery25,
    title: "25",
  },
];

const Gallery = () => {
  return (
    <>
      <div>
        <img
          src={require("../../assets/home/gallery.png")}
          alt=""
          style={{ width: "100%" }}
        />
      </div>
      <h3></h3>
      <ImageList sx={{ margin: 0.5 }} cols={5} rowHeight={250}>
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              height={180}
              src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
              srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
      {/* <img src={require("../../assets/home/gallerygrid.png")} alt="" style={{ width: '100%' ,marginBottom:'-10px'}} /> */}
    </>
  );
};

export default Gallery;
